
import axios from '@axios'
import { payBoxCommandDomain } from '@/mixins'

const getInitialState = () => ({
  payBoxes: [],
  commandList: payBoxCommandDomain.data().CommandsEnum,
  payBoxCommand: {
    storeId: null,
    payboxId: null,
    command: null,
    requiresUserConfirmation: false,
    messageConfirmation: '',
    broadcast: false,
  }
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    payBoxesOptions(state) {
      return state.payBoxes.map(pb => ({
        ...pb,
        value: pb.id,
        label: `Caixa ${pb.number}${pb.description ? ` - ${pb.description}` : ''}`,
      }))
    },
    commandsOptions(state){
      return state.commandList
    },
  },

  mutations: {
    SET_PAY_BOXES(state, payBoxes) {
      state.payBoxes = payBoxes
    },
  },

  actions: {
    async fetchPayBoxes({ commit }, storeId) {
      const { data } = await axios.get(`/api/sales/pay-box`, {
        params: {
          storeId,
          pageSize: 999,
        },
      })

      commit('SET_PAY_BOXES', data.results || [])
    },
  }
}
