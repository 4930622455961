import security from './security'
import catalog from './catalog/index'
import invoice from './invoice'
import store from './store'
import company from './company'
import sale from './sale'
import purchase from './purchase/index'
import pdv from './pdv'
import financial from './financial'
import stock from './stock'
import settings from './settings'
import kiosk from './kiosk'
import reports from './reports'
import bar from './bar'
import dashboard from './dashboard'
import requests from './requests'
import payboxCommands from './paybox-commands'

export default {
  namespaced: true,
  modules: {
    security,
    catalog,
    invoice,
    store,
    company,
    sale,
    purchase,
    pdv,
    financial,
    stock,
    settings,
    kiosk,
    reports,
    bar,
    dashboard,
    requests,
    payboxCommands,
  },
}
