import { mapActions,  mapGetters } from 'vuex'
import isElectron from 'is-electron'
import EventBus from '@/utils/EventBus'

const isElectronLocal = isElectron()

const payboxCommands = {
  data() {
    return {}
  },
  computed: {
    ...mapGetters('pages/pdv/payBoxConfiguration', [
      'currentPayboxConfiguration',
    ]),
  },
  methods: {
    ...mapActions('pages/payboxCommands', [
      'fetchPayBoxCommandWebSocketConfig',
      'connectToPayBoxCommandsWebSocket',
    ]),

    initCommandConfirmationModal() {
      EventBus.$on('open-commands-modal', async (data, callback ) => {
        const response = await this.confirm({
          title: 'Solicitação de Comando no PDV',
          text: this.$t(data.messageConfirmation)
        })
        callback(response)
      })
    }
  },
  async mounted() {
      if (!isElectronLocal) {
        return
      }
      await this.fetchPayBoxCommandWebSocketConfig()
      await this.connectToPayBoxCommandsWebSocket()
      this.initCommandConfirmationModal()
  },
}

export { payboxCommands as default }
