<template class="col-md-1">
  <div
    :class="containerClass || 'mr-1'"
    style="display: inline"
    @click.stop
  >
    <feather-icon
      :id="localTarget"
      icon="InfoIcon"
      size="15"
      class="cursor-pointer"
    />
    <b-popover
      :target="localTarget"
      triggers="focus"
      placement="top"
      boundary="window"
    >
      <template #title>
        <span>{{ localTitle }}</span>
      </template>
      <span>
        {{ instruction }}
        <ul>
          <li
            v-for="item in instructions"
            :key="item.text"
          >
            <strong v-if="item.strong">
              {{ item.text }}
            </strong>
            <span v-else>
              {{ item.text }}
            </span>
          </li>
        </ul>
      </span>
    </b-popover>
  </div>
</template>

<script>
import { BPopover, VBPopover } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BPopover,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: 'Instruções',
    },
    instruction: {
      type: String,
      default: null,
    },
    instructions: {
      type: Array,
      default: () => [],
    },
    target: {
      type: String,
      default: null,
    },

    containerClass: {
      type: String,
      default: null,
    },
  },
  computed: {
    localTitle() {
      return this.$t(this.title)
    },
    localTarget() {
      return (
        this.target ||
        this.defaultId ||
        `${this.instruction}_${this.$attrs?.id?.toString()}` ||
        this.instruction ||
        this.instructions.join('_')
      )
    },
  },
}
</script>
