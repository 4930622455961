const storeBasePath = '/lojas'
const companyBasePath = '/empresas'

export default [
  {
    path: '/lojas/listar',
    name: 'store-list',
    component: () => import('@/views/pages/store/StoreList.vue'),
    meta: {
      pageTitle: 'Lojas',
      navActiveLink: 'store-list',
      resource: 'Store',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Lojas',
        },
        {
          text: 'Gestão de Lojas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lojas/adicionar',
    name: 'store-add',
    component: () => import('@/views/pages/store/StoreMaintain.vue'),
    meta: {
      pageTitle: 'Nova Loja',
      navActiveLink: 'store-list',
      resource: 'Store',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Lojas',
        },
        {
          text: 'Gestão de Lojas',
          to: `${storeBasePath}/listar`,
        },
        {
          text: 'Nova Loja',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lojas/manter/:id',
    name: 'store-maintain',
    component: () => import('@/views/pages/store/StoreMaintain.vue'),
    meta: {
      pageTitle: 'Manter Loja',
      navActiveLink: 'store-list',
      resource: 'Store',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Lojas',
        },
        {
          text: 'Gestão de Lojas',
          to: `${storeBasePath}/listar`,
        },
        {
          text: 'Manter Loja',
          active: true,
        },
      ],
    },
  },
  {
    path: '/empresas/listar',
    name: 'company-list',
    component: () => import('@/views/pages/company/CompanyList.vue'),
    meta: {
      pageTitle: 'Empresas',
      navActiveLink: 'company-list',
      resource: 'Company',
      action: 'Read',
      breadcrumb: [
        {
          text: 'Empresas',
        },
        {
          text: 'Gestão de Empresas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/empresas/adicionar',
    name: 'company-add',
    component: () => import('@/views/pages/company/CompanyMaintain.vue'),
    meta: {
      pageTitle: 'Nova Empresa',
      navActiveLink: 'company-list',
      resource: 'Company',
      action: 'Create',
      breadcrumb: [
        {
          text: 'Empresas',
        },
        {
          text: 'Gestão de Empresas',
          to: `${companyBasePath}/listar`,
        },
        {
          text: 'Nova Empresa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/empresas/manter/:id',
    name: 'company-maintain',
    component: () => import('@/views/pages/company/CompanyMaintain.vue'),
    meta: {
      pageTitle: 'Manter Empresa',
      navActiveLink: 'company-list',
      resource: 'Company',
      action: 'Update',
      breadcrumb: [
        {
          text: 'Empresas',
        },
        {
          text: 'Gestão de Empresas',
          to: `${companyBasePath}/listar`,
        },
        {
          text: 'Manter Empresa',
          active: true,
        },
      ],
    },
  },
]
