<template>
  <date-range-picker
    ref="picker"
    v-model="context.model"
    v-bind="context.attributes"
    :locale-data="localeData"
    :time-picker="timePicker"
    :time-picker24-hour="true"
    :show-week-numbers="false"
    :show-dropdowns="true"
    :auto-apply="true"
    :linked-calendars="true"
    :separator="$t('até')"
    :ranges="dateRanges"
    control-container-class="form-control reportrange-text"
    :opens="popupOpens($el)"
    @update="context.blurHandler"
    @blur="context.blurHandler"
    @start-selection="onSelectStartDate"
    @finish-selection="onSelectFinishDate"
  >
    <template
      #input
      style="min-width: 350px"
    >
      <div class="d-flex justify-content-between align-items-center">
        <span :id="context.attributes.id">
          {{ periodLabel }}
        </span>

        <b-button
          v-if="clearable && showClearButton"
          :id="`btn_clear-${context.attributes.id}`"
          style="padding: 5px"
          class="p-0 m-0"
          variant="outline-light"
          pressed
        >
          <b-icon
            style="cursor: pointer"
            icon="x"
            scale="1.1"
            @click="cleanInput($event)"
          />
        </b-button>
      </div>
    </template>
  </date-range-picker>
</template>

<script>
import { BIcon, BButton } from 'bootstrap-vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { locale } from '@/mixins'
import moment from 'moment'

export default {
  components: {
    BIcon,
    BButton,
    DateRangePicker,
  },
  mixins: [locale],
  props: {
    context: {
      type: Object,
      required: true,
    },

    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      localStartDate: null,
      localEndDate: null,
    }
  },
  computed: {
    popupOpens() {
      return el => {
        if (!el?.getBoundingClientRect) return 'center'
        const widthPopupOverflow = 120
        const boundinRect = el.getBoundingClientRect()

        const widthFromElemToStartWindow = boundinRect.x
        const widthFromElemToEndWindow = window.innerWidth - boundinRect.right

        if (widthFromElemToStartWindow < widthPopupOverflow) return 'right'
        if (widthFromElemToEndWindow < widthPopupOverflow) return 'left'
        return 'center'
      }
    },
    localeData() {
      return {
        firstDay: 1,
        format: 'dd-mm-yyyy HH:mm:ss',
        daysOfWeek: [
          this.$t('daysAbbr.sun'),
          this.$t('daysAbbr.mon'),
          this.$t('daysAbbr.tue'),
          this.$t('daysAbbr.wed'),
          this.$t('daysAbbr.thu'),
          this.$t('daysAbbr.fri'),
          this.$t('daysAbbr.sat'),
        ],
        monthNames: [
          this.$t('monthsAbbr.Jan'),
          this.$t('monthsAbbr.Feb'),
          this.$t('monthsAbbr.Mar'),
          this.$t('monthsAbbr.Apr'),
          this.$t('monthsAbbr.May'),
          this.$t('monthsAbbr.Jun'),
          this.$t('monthsAbbr.Jul'),
          this.$t('monthsAbbr.Aug'),
          this.$t('monthsAbbr.Sep'),
          this.$t('monthsAbbr.Oct'),
          this.$t('monthsAbbr.Nov'),
          this.$t('monthsAbbr.Dec'),
        ],
      }
    },
    dateRanges() {
      const yesterday = moment().add(-1, 'day')
      const lastMonthStart = moment().subtract(1, 'month').startOf('month')
      const lastMonthEnd = moment().subtract(1, 'month').endOf('month')
      const lastYear = moment().add(-1, 'year')

      return {
        [this.$t('Hoje')]: [new Date(moment().startOf('day')), new Date(moment().endOf('day'))],
        [this.$t('Ontem')]: [new Date(yesterday.startOf('day')), new Date(yesterday.endOf('day'))],
        [this.$t('Esta semana')]: [
          new Date(moment().startOf('week').startOf('day')),
          new Date(moment().endOf('week').endOf('day')),
        ],
        [this.$t('Este mês')]: [
          new Date(moment().startOf('month').startOf('day')),
          new Date(moment().endOf('month').endOf('day')),
        ],
        [this.$t('Mês passado')]: [
          new Date(lastMonthStart.startOf('day')),
          new Date(lastMonthEnd.endOf('day')),
        ],
        [this.$t('Este ano')]: [
          new Date(moment().startOf('year').startOf('day')),
          new Date(moment().endOf('year').endOf('day')),
        ],
        [this.$t('Ano anterior')]: [
          new Date(lastYear.startOf('year').startOf('day')),
          new Date(lastYear.endOf('year').endOf('day')),
        ],
      }
    },
    startDate() {
      return this.context.model.startDate
    },
    endDate() {
      return this.context.model.endDate
    },
    periodLabel() {
      const filter = this.timePicker ? this.$options.filters.datetime : this.$options.filters.date
      if (this.context.model.startDate) {
        return this.$t('PERIOD', [
          filter(this.context.model.startDate, false),
          filter(this.context.model.endDate, false),
        ])
      }
      return this.$t('Não selecionado')
    },
    formatter() {
      return {
        date: 'DD-MM-YYYY HH:mm',
        month: 'MMM',
      }
    },
    timePicker() {
      const showTime =
        this.context?.attributes?.timePicker || this.context?.attributes['time-picker']
      return showTime !== undefined ? showTime : true
    },
    showClearButton() {
      return this.context.model.startDate || this.context.model.endDate
    },
  },
  watch: {
    startDate(val) {
      if (val instanceof Date) {
        const data = this.localStartDate || val
        this.context.model.startDate = this.timePicker
          ? moment(data).format()
          : moment(data).startOf('day').format()

        this.localStartDate = null
      }
    },
    endDate(val) {
      if (val instanceof Date) {
        const data = this.localEndDate || val
        this.context.model.endDate = this.timePicker
          ? moment(data).format()
          : moment(data).endOf('day').format()

        this.localEndDate = null
      }
    },
  },
  methods: {
    cleanInput(event) {
      event.stopPropagation()
      this.context.model.startDate = null
      this.context.model.endDate = null
    },

    onSelectStartDate(startDate) {
      if (this.timePicker && !this.startDate) {
        this.localStartDate = moment(startDate).startOf('day').toDate()
      }
    },

    onSelectFinishDate(endDate) {
      if (this.timePicker && !this.endDate) {
        this.localEndDate = moment(endDate).endOf('day').toDate()
      }
    },
  },
}
</script>
<style>
.vue-daterange-picker {
  display: inline !important;
}
.vue-daterange-picker > .reportrange-text {
  /* para evitar que o texto fique oculto qnd tiver quebra de linha */
  height: auto;
  min-height: 38px;
}
.vue-daterange-picker > .reportrange-text span {
  /* para evitar que o texto fique oculto qnd tiver quebra de linha */
  padding-top: 3px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--theme-color) !important;
}
.daterangepicker td.in-range {
  background-color: #c8ebc8;
  filter: brightness(0.9);
}
</style>
