import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import EventBus from '@/utils/EventBus'
import { getAppVersions } from '@/utils/app-utils'
import { storePagination } from '@/mixins/store'
import { sorting , payBoxCommandDomain } from '@/mixins'
import idbInstance from '@/libs/dexie'
import i18n from '@/libs/i18n'
import moment from 'moment'
import managePayBoxCommands from './manage-pay-box-commands'

const payBoxCurrentSaleIdb = idbInstance.table('payBoxCurrentSale')


const getInitialState = () => ({
  ...storePagination.state(),
  commands: [],
  payBoxes: [],
  events: [],
  filters: {
    storeId: null,
    payboxId: null,
    statusCommand: null,
    rangeDate: {
      startDate: moment().startOf('day').format(),
      endDate: moment().endOf('day').format(),
    },
  },
  webSocket: {
    payboxCommands: {
      config: null,
      ready: false,
      connected: false,
      ws: null,
      currentChannelId: null,
      isConnecting: false, // added property to control connection concurrency
    },
  },
  command: null,
  statusCommandList: ['Executed', 'Canceled', 'Pending'],
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: {
    managePayBoxCommands
  },

  getters: {

    ready(state) {
      return state.webSocket.payboxCommands.ready
    },
    payboxCommandWebSocketConnected(state) {
      return state.webSocket.payboxCommands.connected
    },
    payBoxesOptions(state) {
      return state.payBoxes.map(pb => ({
        ...pb,
        value: pb.id,
        label: `Caixa ${pb.number}${pb.description ? ` - ${pb.description}` : ''}`,
      }))
    },
    statusCommandOptions(state){
      return state.statusCommandList.map(statusCommand => ({
        ...statusCommand,
        value: statusCommand,
        label: statusCommand
      }))
    },
  },

  mutations: {
    ...storePagination.mutations,
    SET_PAYBOX_COMMAND_READY(state, ready) {
      state.webSocket.payboxCommands.ready = ready
    },
    SET_PAYBOX_COMMAND_WEBSOCKET_CONNECTED(state, connected) {
      state.webSocket.payboxCommands.connected = connected
    },
    SET_PAYBOX_COMMAND_WS_CONFIG(state, data) {
      state.webSocket.payboxCommands.config = data
    },
    SET_PAY_BOXES(state, payBoxes) {
      state.payBoxes = payBoxes
    },
    SET_COMMANDS(state, commands){
      state.commands = commands
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const { filters, paging, payBoxes, commands } = getInitialState()
      state.filters = filters
      state.payBoxes = payBoxes
      state.paging = paging
      state.commands = commands
    },
    SET_PAYBOX_COMMAND_CONNECTING(state, flag) {
      state.webSocket.payboxCommands.isConnecting = flag
    },
  },

  actions: {
    ...storePagination.actions,
    async fetchPayBoxCommandWebSocketConfig({ commit }) {
      const { data } = await axios.get('/api/settings/paybox-command/ws')
      commit('SET_PAYBOX_COMMAND_WS_CONFIG', data)
    },
    connectToPayBoxCommandsWebSocket({ commit, state, dispatch, rootGetters }) {

      // Exit if a connection is already in progress
      if(state.webSocket.payboxCommands.isConnecting) {
        console.debug('[payboxCommands websocket] Already connecting...')
        return
      }
      commit('SET_PAYBOX_COMMAND_CONNECTING', true)

      const { webSocketEndpoint } = state.webSocket.payboxCommands.config
      console.debug('[payboxCommands websocket] Connecting to', webSocketEndpoint)

      const {
        id: payBoxId,
      } = rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']


      if(state.webSocket.payboxCommands.connected){
        const isSamePayBox = `paybox-commands-events-${payBoxId}` === state.webSocket.payboxCommands.currentChannelId
        if(isSamePayBox){
          console.debug('[payboxCommands websocket] WebSocket Already Connected... ')
          commit('SET_PAYBOX_COMMAND_CONNECTING', false)
          return
        }
      }

      state.webSocket.payboxCommands.ws = new WebSocket(`${webSocketEndpoint}?Token=${useJwt.getToken()}`)
      state.webSocket.payboxCommands.ws.onerror = error => {
        commit('SET_PAYBOX_COMMAND_READY', true)
        commit('SET_PAYBOX_COMMAND_WEBSOCKET_CONNECTED', false)
        commit('SET_PAYBOX_COMMAND_CONNECTING', false)
        console.debug('[payboxCommands websocket] Connection error', error)
      }

      state.webSocket.payboxCommands.ws.onopen = () => {
        console.debug('[payboxCommands websocket] Connection opened')
        dispatch('joinPayBoxCommandsChannel')
        commit('SET_PAYBOX_COMMAND_READY', true)
        commit('SET_PAYBOX_COMMAND_CONNECTING', false)
      }

      state.webSocket.payboxCommands.ws.onmessage = event => {
        const data = JSON.parse(event.data)
        dispatch('handleIncomingPayBoxCommandsEvent', data)
      }

      state.webSocket.payboxCommands.ws.onclose = () => {
        console.debug('[payboxCommands websocket] Connection closed')
        commit('SET_PAYBOX_COMMAND_WEBSOCKET_CONNECTED', false)
        commit('SET_PAYBOX_COMMAND_CONNECTING', false)
        dispatch('connectToPayBoxCommandsWebSocket')
      }

    },

    async joinPayBoxCommandsChannel({ commit, state, rootGetters }) {
      const {
        id: payBoxId,
      } = rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      if (state.webSocket.payboxCommands.ws) {
        if (state.webSocket.payboxCommands.currentChannelId) {
          const message = {
            action: 'leave',
            channelId: state.webSocket.payboxCommands.currentChannelId,
          }

          console.debug('[payboxCommands websocket] Leaving channel ', message.channelId)

          state.webSocket.payboxCommands.ws.send(JSON.stringify(message))
        }

        if (payBoxId) {
          const message = {
            action: 'join',
            channelId: `paybox-commands-events-${payBoxId}`,
          }

          console.debug('[payboxCommands websocket] Joining channel ', message.channelId)

          state.webSocket.payboxCommands.ws.send(JSON.stringify(message))

          commit('SET_PAYBOX_COMMAND_WEBSOCKET_CONNECTED', true)

          state.webSocket.payboxCommands.currentChannelId = message.channelId
        } else {
          commit('SET_PAYBOX_COMMAND_WEBSOCKET_CONNECTED', false)
        }
      }
    },

    async handleIncomingPayBoxCommandsEvent({ dispatch, state, rootGetters }, data) {
      const {
        id: payBoxId,
      } = rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      console.debug('[payboxCommands websocket] receiving data', data)

      if (state.webSocket.payboxCommands.currentChannelId !== data.channelId && data.channelId !== 'paybox-commands-events-*') {
        console.debug('[payboxCommands websocket] received message from different channel, ignore', data)
        return
      }

      const content = JSON.parse(data.content)
      const id = content.commandIds.find(cmd => cmd.payBox === payBoxId)?.id

      if(content.requiresUserConfirmation){
        EventBus.$emit('open-commands-modal', content, async response => {
          if(response){
            await dispatch('executeCommand', {content, id})
          }else{
            await dispatch('updateCommandResponse', {id, status: 'Canceled'})
          }
        })
      }else{
        await dispatch('executeCommand', {content, id})
      }
    },


    async executeCommand({dispatch, rootGetters, rootState}, {content, id}){
      let result = ''
      const additionalInformation = ''

      try{
        switch (content.command) {
          case payBoxCommandDomain.data().CommandsEnum.PING: {
            const responsePing = await window.electronAPI.pingPongUsingAgent('ping')
            console.debug('result ping pong: ', responsePing)
            result = {
              systemInfo: await window.electronAPI.system.systemInfo(),
              responsePingAgent: responsePing,
              appVersions: getAppVersions()
            }

            break
          }
          case payBoxCommandDomain.data().CommandsEnum.CLEAN_OPEN_SALE:{
            const payBoxStorage = await payBoxCurrentSaleIdb.limit(1).toArray()
            const data = payBoxStorage[0]
            if (data) {
              result = {
                openSale: data.payload
              }
            }
            await dispatch('pages/pdv/payBox/cleanState', {}, { root: true })

            break
          }
          case payBoxCommandDomain.data().CommandsEnum.REFRESH_PAYBOX_CONFIG: {
            await dispatch('pages/pdv/payBoxConfiguration/fetchPayBox',{},{root: true,})
            break
          }
          case payBoxCommandDomain.data().CommandsEnum.SAT_CONSULT_STATUS: {
            const invoiceAgentConfigPayload = rootGetters['pages/pdv/payBoxConfiguration/invoiceAgentConfigPayload']

            const resultConsultStatus = await window.electronAPI.invoice.satCheckStatus(
              invoiceAgentConfigPayload
            )
            console.debug('result sat consult status: ', resultConsultStatus)
            result = resultConsultStatus.parsedResponse

            break
          }
          case payBoxCommandDomain.data().CommandsEnum.SAT_EXTRACT_LOGS: {
            const invoiceAgentConfigPayload = rootGetters['pages/pdv/payBoxConfiguration/invoiceAgentConfigPayload']

            const resultExtractLogs = await window.electronAPI.invoice.extractLogsSat(
              invoiceAgentConfigPayload
            )
            console.debug('result sat extract logs: ', resultExtractLogs)
            result = resultExtractLogs

            break
          }
          case payBoxCommandDomain.data().CommandsEnum.SAT_END_TO_END_TEST: {
            const { payBox } = rootState.pages.pdv.payBoxConfiguration
            const invoiceAgentConfigPayload = rootGetters['pages/pdv/payBoxConfiguration/invoiceAgentConfigPayload']
            const max = 999
            const min = 100
            const randomNumber = Math.floor(Math.random() * (max - min) + min)
            console.debug('testEndToEndSat | randomNumber', this.randomNumber)

            const endToEndData = {
              aleatoryCode: `${randomNumber}`,
              storeCnpj: payBox.store.document,
              stateRegistration: payBox.store.stateRegistration,
              signatureCommercialApp: payBox.sat.signatureCommercialApp,
            }

            const data = {
              ...invoiceAgentConfigPayload,
              request: JSON.stringify(endToEndData),
            }

            result = await window.electronAPI.invoice.satTestEndToEnd({
              ...data,
            })

            console.debug('result sat test end to end: ', result)

            break
          }
          case payBoxCommandDomain.data().CommandsEnum.TEF_INITIALIZE: {
            result = await dispatch('pages/pdv/payBoxTef/initialize',{},{root: true,})
            console.debug('result tef initialize: ', result)
            break
          }
          case payBoxCommandDomain.data().CommandsEnum.TEF_AUTHENTICATE: {
            result = await dispatch('pages/pdv/payBoxTef/authenticate',{},{root: true,})
            console.debug('result tef authenticate: ', result)

            await dispatch('pages/pdv/payBoxConfiguration/updateTefInformation',
              {businessCode: result.codes.businessCode, storeCode: result.codes.storeCode, terminalCode: result.codes.terminalCode },
              {root: true}
            )
            break
          }
          case payBoxCommandDomain.data().CommandsEnum.TEF_REFRESH_TABLES: {
            result = await dispatch('pages/pdv/payBoxTef/refreshTables',{},{root: true,})
            console.debug('result tef refreshTables: ', result)
            break
          }
          case payBoxCommandDomain.data().CommandsEnum.ADJUST_DATE_AND_TIME: {
            const data = {
              request: content.request
            }
            const responseAdjustDateTime = await window.electronAPI.system.updateDateTime({
              ...data,
            })
            console.debug('result adjust date time: ', responseAdjustDateTime)
            result = responseAdjustDateTime
            break
          }
          case payBoxCommandDomain.data().CommandsEnum.KILL_SERVICES: {
            await dispatch('updateCommandResponse', {id, response: i18n.t(result), status: 'Executed', additionalInformation})
            await window.electronAPI.system.killServices()
            break
          }
          case payBoxCommandDomain.data().CommandsEnum.UPDATE_VERSIONS:{
            result = await window.electronAPI.system.checkForAppUpdates(
              JSON.parse(content.request),
            )
            console.debug('result check for app updates: ', result)
            break
          }
          case payBoxCommandDomain.data().CommandsEnum.GET_OPEN_SALE:{
            const payBoxStorage = await payBoxCurrentSaleIdb.limit(1).toArray()
            const data = payBoxStorage[0]
            if (data) {
              result = {
                openSale: data.payload
              }
              console.debug('result get open sale: ', result)
            }
            break
          }
          default:
            break
        }

      }catch(err){
        result = err
      }
      await dispatch('updateCommandResponse', {id, response: i18n.t(result), status: 'Executed', additionalInformation})
    },

    async updateCommandResponse(store,{id, response, status, additionalInformation}){
      const data = {
        id,
        status,
        commandResponse: JSON.stringify(response),
        additionalInformation
      }

      await axios.put(
        '/api/sales/pay-box/command',
         data
      )
    },

    async fetchPayBoxes({ commit }, storeId) {
      const { data } = await axios.get(`/api/sales/pay-box`, {
        params: {
          storeId,
          pageSize: 999,
        },
      })

      commit('SET_PAY_BOXES', data.results || [])
    },

    async fetchCommands({ commit, state }){
      const { data } = await axios.get(`/api/sales/pay-box/command`, {
        params: {
          storeId: state.filters.storeId,
          payboxId: state.filters.payboxId,
          status: state.filters.statusCommand,
          dateFrom: state.filters.rangeDate.startDate,
          dateTo: state.filters.rangeDate.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state)
        },
      })

      commit('SET_COMMANDS', data.results || [])
    },
    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  }
}
