import { sorting } from '@/mixins'
import { storePagination } from '@/mixins/store'
import axios from '@axios'
import moment from 'moment'

export const getInitialState = () => ({
  ...storePagination.state(),
  sorting: {
    sortBy: 'CreatedDate',
    sortDesc: true,
  },
  serviceRequests: [],
  serviceAreas: [],
  serviceCategories: [],
  filters: {
    id: null,
    status: null,
    rangeDate: {
      startDate: moment().add(-30, 'days').startOf('day').format(),
      endDate: moment().endOf('day').format(),
    },
    serviceAreaId: null,
    serviceCategoryId: null,
    storeId: null,
    userId: null,
    description: null,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    serviceAreaOptions(state) {
      return state.serviceAreas?.map(serviceArea => ({
        label: serviceArea.name,
        value: serviceArea.id,
      }))
    },
    serviceCategoryOptions(state) {
      return state.serviceCategories
        ?.map(serviceCategory => ({
          ...serviceCategory,
          label: serviceCategory.name,
          value: serviceCategory.id,
        }))
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_SERVICE_REQUESTS(state, serviceRequests) {
      state.serviceRequests = serviceRequests
    },
    SET_SERVICE_AREA(state, serviceAreas) {
      state.serviceAreas = serviceAreas
    },
    SET_SERVICE_CATEGORY(state, serviceCategories) {
      state.serviceCategories = serviceCategories
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
    CLEAN_PAGING(state) {
      state.paging = getInitialState().paging
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchAllServiceArea({ commit }) {
      const response = await axios.get('/api/service/areas', {
        params: {
          ative: true,
          pageSize: 0,
        },
      })
      commit('SET_SERVICE_AREA', response.data.results)
    },
    async fetchServiceCategory({ commit }, serviceAreaId) {
      const response = await axios.get('/api/service/categories', {
        params: {
          ative: true,
          serviceAreaId,
          pageSize: 0,
        },
      })
      commit('SET_SERVICE_CATEGORY', response.data.results)
    },
    async fetchServiceRequests({ state, commit }, exportData) {
      const { id, status, description, rangeDate, serviceAreaId, serviceCategoryId, storeId, userId } =
        state.filters

      const { data } = await axios.get('/api/service/requests', {
        headers: {
          Accept: exportData
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'application/json',
        },
        params: {
          id,
          status,
          description,
          startDate: rangeDate?.startDate,
          endDate: rangeDate?.endDate,
          serviceAreaId,
          serviceCategoryId,
          storeId,
          userId,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
        responseType: exportData ? 'blob' : 'json',
      })

      if (exportData) {
        return data
      }

      commit('SET_SERVICE_REQUESTS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })

      return data
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    cleanPaging({ commit }) {
      commit('CLEAN_PAGING')
    },
  },
}
