export default {
  title: 'Lojas',
  icon: 'building',
  iconPack: 'bootstrap',
  children: [
    {
      title: 'Gestão de Empresas',
      route: 'company-list',
      resource: 'Company',
      action: 'Read',
    },
    {
      title: 'Gestão de Lojas',
      route: 'store-list',
      resource: 'Store',
      action: 'Read',
    },
  ],
}
