const CommandsEnum = {
  PING: 'Ping',
  CLEAN_OPEN_SALE: 'CleanOpenSale',
  REFRESH_PAYBOX_CONFIG: 'RefreshPayBoxConfig',
  SAT_CONSULT_STATUS: 'SatConsultStatus',
  SAT_EXTRACT_LOGS: 'SatExtractLogs',
  SAT_END_TO_END_TEST: 'SatEndToEndTest',
  TEF_INITIALIZE: 'TefInitialize',
  TEF_AUTHENTICATE: 'TefAuthenticate',
  TEF_REFRESH_TABLES: 'TefRefreshTables',
  ADJUST_DATE_AND_TIME: 'AdjustDateAndTime',
  KILL_SERVICES: 'KillServices',
  UPDATE_VERSIONS: 'UpdateVersions',
  GET_OPEN_SALE: 'GetOpenSale',
}

const payBoxCommandDomain = {
  data() {
    return {
      CommandsEnum,
    }
  },

  computed: {
    payBoxCommandsLabel() {
      return {
        [this.CommandsEnum.PING]: this.$t('Ping'),
        [this.CommandsEnum.CLEAN_OPEN_SALE]: this.$t('Limpar Venda Aberta'),
        [this.CommandsEnum.REFRESH_PAYBOX_CONFIG]: this.$t('Ler Configurações do Caixa'),
        [this.CommandsEnum.SAT_CONSULT_STATUS]: this.$t('Consultar Status do Sat'),
        [this.CommandsEnum.SAT_EXTRACT_LOGS]: this.$t('Extrair Logs do Sat'),
        [this.CommandsEnum.SAT_END_TO_END_TEST]: this.$t('Executar Teste Fim a Fim no Sat'),
        [this.CommandsEnum.TEF_INITIALIZE]: this.$t('Inicializar Tef'),
        [this.CommandsEnum.TEF_AUTHENTICATE]: this.$t('Autenticar Tef'),
        [this.CommandsEnum.TEF_REFRESH_TABLES]: this.$t('Atualizar Tabelas Tef'),
        [this.CommandsEnum.ADJUST_DATE_AND_TIME]: this.$t('Atualizar Data e Hora do Computador'),
        [this.CommandsEnum.KILL_SERVICES]: this.$t('Forçar Encerramento das Aplicações'),
        [this.CommandsEnum.UPDATE_VERSIONS]: this.$t('Atualizar Versões das Aplicações'),
        [this.CommandsEnum.GET_OPEN_SALE]: this.$t('Consultar a venda em aberto no PDV'),
      }
    },
    payBoxCommandsMessageConfirmationTemplate(){
      return {
        [this.CommandsEnum.PING]: this.$t('PAYBOX_COMMAND.PING'),
        [this.CommandsEnum.CLEAN_OPEN_SALE]: this.$t('PAYBOX_COMMAND.CLEAN_OPEN_SALE'),
        [this.CommandsEnum.REFRESH_PAYBOX_CONFIG]: this.$t('PAYBOX_COMMAND.REFRESH_PAYBOX_CONFIG'),
        [this.CommandsEnum.SAT_CONSULT_STATUS]: this.$t('PAYBOX_COMMAND.SAT_CONSULT_STATUS'),
        [this.CommandsEnum.SAT_EXTRACT_LOGS]: this.$t('PAYBOX_COMMAND.SAT_EXTRACT_LOGS'),
        [this.CommandsEnum.SAT_END_TO_END_TEST]: this.$t('PAYBOX_COMMAND.SAT_END_TO_END_TEST'),
        [this.CommandsEnum.TEF_INITIALIZE]: this.$t('PAYBOX_COMMAND.TEF_INITIALIZE'),
        [this.CommandsEnum.TEF_AUTHENTICATE]: this.$t('PAYBOX_COMMAND.TEF_AUTHENTICATE'),
        [this.CommandsEnum.TEF_REFRESH_TABLES]: this.$t('PAYBOX_COMMAND.TEF_REFRESH_TABLES'),
        [this.CommandsEnum.ADJUST_DATE_AND_TIME]: this.$t('PAYBOX_COMMAND.ADJUST_DATE_AND_TIME'),
        [this.CommandsEnum.KILL_SERVICES]: this.$t('PAYBOX_COMMAND.KILL_SERVICES'),
        [this.CommandsEnum.UPDATE_VERSIONS]: this.$t('PAYBOX_COMMAND.UPDATE_VERSIONS'),
        [this.CommandsEnum.GET_OPEN_SALE]: this.$t('PAYBOX_COMMAND.GET_OPEN_SALE'),
      }
    }
  },

  methods: {
    payBoxCommandsOptions() {
      return [
        { value: CommandsEnum.PING, label: this.$t('Ping') },
        { value: CommandsEnum.CLEAN_OPEN_SALE, label: this.$t('Limpar Venda Aberta')},
        { value: CommandsEnum.REFRESH_PAYBOX_CONFIG, label: this.$t('Ler Configurações do Caixa')},
        { value: CommandsEnum.SAT_CONSULT_STATUS, label: this.$t('Consultar Status do Sat')},
        { value: CommandsEnum.SAT_EXTRACT_LOGS, label: this.$t('Extrair Logs do Sat')},
        { value: CommandsEnum.SAT_END_TO_END_TEST, label: this.$t('Executar Teste Fim a Fim no Sat')},
        { value: CommandsEnum.TEF_INITIALIZE, label: this.$t('Inicializar Tef')},
        { value: CommandsEnum.TEF_AUTHENTICATE, label: this.$t('Autenticar Tef')},
        { value: CommandsEnum.TEF_REFRESH_TABLES, label: this.$t('Atualizar Tabelas Tef')},
        { value: CommandsEnum.ADJUST_DATE_AND_TIME, label: this.$t('Atualizar Data e Hora do Computador')},
        { value: CommandsEnum.KILL_SERVICES, label: this.$t('Forçar Encerramento das Aplicações')},
        { value: CommandsEnum.UPDATE_VERSIONS, label: this.$t('Atualizar Versões das Aplicações')},
        { value: CommandsEnum.GET_OPEN_SALE, label: this.$t('Consultar a venda em aberto no PDV')},
      ]
    },
  },
}

export default payBoxCommandDomain
