import axios from '@axios'
import moment from 'moment'
import { storePagination } from '@/mixins/store'
import { financialDomain, sorting } from '@/mixins'
import receivableMaintain from './receivable-maintain'
import receivablesBulkMaintain from './receivables-bulk-maintain'

const defaultFilter = () => ({
  storeId: null,
  description: null,
  receivableOriginId: null,
  customerId: null,
  saleId: null,
  paymentMethods: '',
  paymentStatus: 'isPending',
  value: null,
  valueFrom: null,
  valueTo: null,
  periodType: financialDomain.data().receivablePeriodTypeEnum.DUE_DATE,
  period: {
    startDate: moment().add(-30, 'days').startOf('day').format(),
    endDate: moment().add(30, 'days').endOf('day').format(),
  },
  nsu: null,
  isPaid: null,
})

const getInitialState = () => ({
  ...storePagination.state(),
  filtersToReport: {
    ...defaultFilter(),
    paymentStatus: null,
  },
  filters: defaultFilter(),
  receivables: [],
  reportData: null,
  selectedInstallments: [],
  summaryFields: null,
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: { receivableMaintain, receivablesBulkMaintain },

  mutations: {
    ...storePagination.mutations,

    SET_RECEIVABLES(state, receivables) {
      state.receivables = receivables
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_SORTING(state, sortingData) {
      state.sorting = sortingData
    },
    SET_SELECTED_INSTALLMENTS(state, selectedInstallments) {
      state.selectedInstallments = selectedInstallments
    },
    SET_SUMMARY(state, summaryFields) {
      state.summaryFields = summaryFields
    },
    SET_REPORT_DATA(state, reportData) {
      state.reportData = reportData
    },
    CLEAN_STATE(state) {
      const { filters, paging, receivables, selectedInstallments, summaryFields } =
        getInitialState()
      state.filters = filters
      state.receivables = receivables
      state.selectedInstallments = selectedInstallments
      state.paging = paging
      state.summaryFields = summaryFields
    },
    CLEAN_FILTER_TO_REPORT(state) {
      const { filtersToReport } = getInitialState()
      state.filtersToReport = filtersToReport
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchReceivables({ commit, state }) {
      const { paymentStatus, periodType, period } = state.filters

      const { startDate, endDate } = period
      const { CREATED_DATE, DUE_DATE, ISSUE_DATE, PAYMENT_DATE } =
        financialDomain.data().receivablePeriodTypeEnum
      const periodParams = {
        [CREATED_DATE]: {
          createdDateFrom: startDate,
          createdDateTo: endDate,
        },
        [DUE_DATE]: {
          dueDateFrom: startDate,
          dueDateTo: endDate,
        },
        [ISSUE_DATE]: {
          issueDateFrom: startDate,
          issueDateTo: endDate,
        },
        [PAYMENT_DATE]: {
          paymentDateFrom: startDate,
          paymentDateTo: endDate,
        },
      }
      const periodFilter = periodParams[periodType] || {}

      const params = {
        ...periodFilter,
        ...state.filters,
        sortBy: sorting.methods.getSorting(state),
        pageSize: state.paging.pageSize,
        pageIndex: state.paging.currentPage - 1,
      }

      if (paymentStatus && !state.filters.isPaid) {
        params[paymentStatus] = true
      }

      const { data } = await axios.get('/api/accounting/receivable-installments', {
        params,
      })

      const installmentsFormated = data.results.map(installment => ({
        ...installment,
        checkedToReceive: state.selectedInstallments.some(
          selected => selected.id === installment.id
        ),
      }))

      commit('SET_RECEIVABLES', installmentsFormated || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount || 0,
        rowsInCurrentPage: data.rowsInCurrentPage || 0,
      })
      commit('SET_SUMMARY', data.summaryFields)
    },

    addInstallmentsToPay({ commit, state }, item) {
      const { receivable } = item
      if (!receivable || !receivable.store) throw new Error('Dados no item faltando')

      if (state.selectedInstallments.length > 0) {
        const existingStoreId = state.selectedInstallments[0]?.storeId
        if (receivable.store.id !== existingStoreId)
          throw new Error('Não é possível realizar pagamento em massa para lojas diferentes.')
      }

      const newSelectedInstallments = [
        ...state.selectedInstallments,
        { ...item, storeId: receivable.store.id },
      ]
      commit('SET_SELECTED_INSTALLMENTS', newSelectedInstallments)
    },
    removeInstallmentsToPay({ commit, state }, item) {
      const newList = [...state.selectedInstallments]
      const indexFound = newList.findIndex(inst => inst.id === item.id)
      newList.splice(indexFound, 1)
      commit('SET_SELECTED_INSTALLMENTS', newList)
    },

    setFilters({ commit }, filters) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', filters)
    },

    setSorting({ commit }, sortingData) {
      commit('SET_SORTING', sortingData)
    },

    setPeriodType({ commit, state }, periodType) {
      commit('SET_FILTERS', {
        ...state.filters,
        periodType,
      })
    },

    resetFilters({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', getInitialState().filters)
    },
    resetFiltersForConciliationMatch({ state, commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_FILTERS', {
        ...getInitialState().filters,
        storeId: state.filters.storeId,
        isPaid: state.filters.isPaid,
      })
    },

    cleanInstallmentsToReceive({ commit }) {
      commit('SET_SELECTED_INSTALLMENTS', [])
    },
    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    cleanFilterToReport({ commit }) {
      commit('CLEAN_FILTER_TO_REPORT')
    },

    async reversePayment({ dispatch }, financialWriteOffId) {
      await axios.patch(`/api/financial/write-offs/${financialWriteOffId}/reverse-payment`)
      dispatch('fetchReceivables')
    },
    async generateReport({ state, commit }) {
      const {
        value,
        storeId,
        description,
        customerId,
        saleId,
        paymentMethods,
        paymentStatus,
        periodType,
        period,
        valueFrom,
        valueTo,
        nsu,
      } = state.filtersToReport

      const { startDate, endDate } = period
      const { CREATED_DATE, DUE_DATE, ISSUE_DATE, PAYMENT_DATE } =
        financialDomain.data().receivablePeriodTypeEnum
      const periodParams = {
        [CREATED_DATE]: {
          createdDateFrom: startDate,
          createdDateTo: endDate,
        },
        [DUE_DATE]: {
          dueDateFrom: startDate,
          dueDateTo: endDate,
        },
        [ISSUE_DATE]: {
          issueDateFrom: startDate,
          issueDateTo: endDate,
        },
        [PAYMENT_DATE]: {
          paymentDateFrom: startDate,
          paymentDateTo: endDate,
        },
      }
      const periodFilter = periodParams[periodType] || {}

      const params = {
        storeId,
        description,
        customerId,
        saleId,
        paymentMethods,
        value,
        valueFrom,
        valueTo,
        nsu,
        sortBy: 'CreatedDate ASC',
        pageSize: 0,
        pageIndex: 0,
        ...periodFilter,
      }

      if (paymentStatus) {
        params[paymentStatus] = true
      }

      const { data } = await axios.get('/api/accounting/receivables/download', {
        params,
        responseType: 'blob',
      })

      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = window.URL.createObjectURL(blob)
      commit('SET_REPORT_DATA', url)
    },
  },
}
