const companyDomains = {
  computed: {
    companyTypesLabel() {
      return {
        Store: this.$t('Loja'),
        Hub: this.$t('Hub'),
        DistributionCenter: this.$t('Centro de distribuição'),
      }
    },
  },

  methods: {
    companyTypes() {
      return [
        { value: 'Store', label: this.$t('Loja') },
        { value: 'Hub', label: this.$t('Hub') },
        { value: 'DistributionCenter', label: this.$t('Centro de distribuição') },
      ]
    },
  },
}

export default companyDomains
