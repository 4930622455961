
const serviceTypes = {
  data() {
    return {
      serviceRequestStatusOptions: [
        {
          value: 'Completed',
          label: this.$t(`ServiceRequest.Status.Completed`),
          classStyle: 'success',
        },
        {
          value: 'Rejected',
          label: this.$t(`ServiceRequest.Status.Rejected`),
          classStyle: 'danger',
        },
        {
          value: 'InProgress',
          label: this.$t(`ServiceRequest.Status.InProgress`),
          classStyle: 'info',
        },
        {
          value: 'Canceled',
          label: this.$t(`ServiceRequest.Status.Canceled`),
          classStyle: 'danger',
          // style: object, // objeto CSS para estilos ex: { 'background-color': 'blue' }
        },
        {
          value: 'ChangesRequested',
          label: this.$t(`ServiceRequest.Status.ChangesRequested`),
          classStyle: 'warning',
        },
      ],
    }
  },
  computed: {
   serviceCategoryRequestedForEnum() {
      return {
        STORE: 'Store',
        PERSON: 'Person',
      }
    },
    getServiceCategoryRequestedFor() {
      return {
        Store: this.$t('Loja'),
        Person: this.$t('Pessoa'),
      }
    },
    statusServiceRequest() {
      return {
        Canceled: this.$t('Cancelado'),
        Rejected: this.$t('Reprovado'),
        InProgress: this.$t('Em andamento'),
        Completed: this.$t('Finalizado'),
        ChangesRequested: this.$t('Alterações solicitadas'),
      }
    },
  },

  methods: {
    serviceCategoryRequestedFor() {
      return [
        { value: 'Store', label: this.$t('Loja') },
        { value: 'Person', label: this.$t('Pessoa') },
      ]
    },
  },
}

export default serviceTypes
