import axios from '@axios'
import { address, stringUtils, storePagination, sorting } from '@/mixins'

// const image = 'https://dbechope.s3-sa-east-1.amazonaws.com/images_site/Loja_1_Araraquara.jpeg'

const getInitialState = () => ({
  ...storePagination.state(),
  apiData: {
    telephones: [],
  },
  filters: {
    id: null,
    tradingName: null,
    companyName: null,
    cnpj: null,
    publicPlace: null,
    province: null,
    city: null,
    email: null,
    active: null,
    type: null,
    businessModel: null,
    headId: null,
    directorId: null,
    directorCommercialId: null,
    investors: null,
    region: null,
  },
  companies: [],
  heads: [],
  directors: [],
  directorsCommercial: [],
  investors: [],
  selectedStoreId: null,
  company: {
    id: null,
    active: true,
    activeOnline: false,
    tradingName: null,
    companyName: null,
    cnpj: null,
    stateRegistration: null,
    stateRegistrationOfTaxSubstitute: null,
    municipalInscription: null,
    taxCode: null,
    taxRegime: null,
    address: address.data().address,
    telephones: [],
    email: null,
    type: null,
    businessModel: null,
    headId: null,
    directorId: null,
    directorCommercialId: null,
    investors: [],
    invoiceSerialNumber: null,
    invoiceLastNumber: null,
    digitalCertificateUrl: null,
    digitalCertificatePassword: null,
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    digitalCertificate(state) {
      if (!state.company.digitalCertificateUrl) return null
      return {
        url: state.company.digitalCertificateUrl,
        name: state.company.digitalCertificateUrl,
        type: stringUtils.methods.getFileType(state.company.digitalCertificateUrl),
        size: 0,
        ext: stringUtils.methods.getFileExtension(state.company.digitalCertificateUrl),
        urlValue: state.company.digitalCertificateUrl,
      }
    },
    digitalCertificateName(state) {
      return stringUtils.methods.getFileName(state.company.digitalCertificateUrl)
    },
    headOptions(state) {
      return state.heads.map(head => ({
        ...head,
        value: head.id,
        label: head.name,
      }))
    },
    directorOptions(state) {
      return state.directors.map(director => ({
        ...director,
        value: director.id,
        label: director.name,
      }))
    },
    directorCommercialOptions(state) {
      return state.directorsCommercial.map(directorCommercial => ({
        ...directorCommercial,
        value: directorCommercial.id,
        label: directorCommercial.name,
      }))
    },
    investorsOptions(state) {
      return state.investors.map(investor => ({
        value: investor.id,
        label: investor.name,
      }))
    },
  },

  mutations: {
    ...storePagination.mutations,

    SET_API_DATA_TELEPHONES(state, telephones) {
      state.apiData.telephones = telephones
    },
    SET_COMPANIES(state, companies) {
      state.companies = companies
    },
    SET_HEADS(state, heads) {
      state.heads = heads
    },
    SET_DIRECTORS(state, directors) {
      state.directors = directors
    },
    SET_DIRECTORS_COMMERCIAL(state, directorsCommercial) {
      state.directorsCommercial = directorsCommercial
    },
    SET_INVESTORS(state, investors) {
      state.investors = investors
    },
    SET_COMPANY(state, company) {
      state.company = {
        ...company,
        investors: company.investors?.map(investorId => investorId.toString()),
      }
      state.company.imageObject = {
        url: company.image,
        name: company.image,
        type: stringUtils.methods.getFileType(company.image),
        size: 0,
        ext: stringUtils.methods.getFileExtension(company.image),
        urlValue: company.image,
      }
    },
    RESET_FILTERS(state) {
      const { filters } = getInitialState()
      state.filters = filters
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchCompanies({ commit, state }) {
      const response = await axios.get('/api/companies', {
        params: {
          id: state.filters.id,
          tradingName: state.filters.tradingName,
          companyName: state.filters.companyName,
          cnpj: state.filters.cnpj,
          publicPlace: state.filters.publicPlace,
          province: state.filters.province,
          city: state.filters.city,
          email: state.filters.email,
          active: state.filters.active,
          type: state.filters.type,
          businessModel: state.filters.businessModel,
          headId: state.filters.headId,
          directorId: state.filters.directorId,
          directorCommercialId: state.filters.directorCommercialId,
          region: state.filters.region,
          sortBy: sorting.methods.getSorting(state),
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
        },
      })
      commit('SET_COMPANIES', response.data.results)
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: response.data.rowCount,
        rowsInCurrentPage: response.data.rowsInCurrentPage,
      })
    },

    async fetchHeads({ commit }) {
      const response = await axios.get('/api/users/heads', {
        params: {
          pageSize: 0,
          sortBy: 'Name ASC',
        },
      })
      commit('SET_HEADS', response.data.results)
    },

    async fetchDirectors({ commit }) {
      const response = await axios.get('/api/users/directors', {
        params: {
          pageSize: 0,
          sortBy: 'Name ASC',
        },
      })
      commit('SET_DIRECTORS', response.data.results)
    },

    async fetchDirectorsCommercial({ commit }) {
      const response = await axios.get('/api/users/directors-commercial', {
        params: {
          pageSize: 0,
          sortBy: 'Name ASC',
        },
      })
      commit('SET_DIRECTORS_COMMERCIAL', response.data.results)
    },

    async fetchInvestors({ commit }) {
      const response = await axios.get('/api/investors', {
        params: {
          pageSize: 0,
          sortBy: 'Name ASC',
        },
      })
      commit('SET_INVESTORS', response.data.results)
    },

    async fetchCompany({ commit }, companyId) {
      const response = await axios.get(`/api/companies/${companyId}`)

      commit('SET_COMPANY', {
        ...response.data,
        headquartersStoreId: response.data.headquartersStoreId?.toString(),
      })
      commit('SET_API_DATA_TELEPHONES', response.data?.telephones.map(t => ({ ...t })) || [])
      return response.data
    },

    async clearStore({ commit }) {
      commit('SET_COMPANY', getInitialState().company)
    },

    resetFilters({ commit }) {
      commit('RESET_FILTERS')
    },
  },
}
