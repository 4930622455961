import _ from 'lodash'
import { localListEnrichLocalId } from '@/store/utils'
import Decimal from 'decimal.js-light'
import { payBoxUtils } from '.'
import discountTypes from '../dominio/discount-types'

export const getInitialSaleItem = () => ({
  id: null,
  localId: null,
  productKitId: null,
  product: null,
  priceTable: { id: null },
  priceTableId: null,
  promotionId: null,
  saleItemAssociatedId: null,
  skuAssociatedId: null,
  skuTieredPriceId: null,
  choppGiftCardId: null,

  orderQuantity: 0,
  quantity: 1,
  maxQuantity: -1,

  unitDiscountAuth: null,

  discountType: discountTypes.computed.discountTypesEnum().VALUE,
  unitDiscount: 0,
  discountPriceTable: 0,

  /**
   * valor unitário na config do produto
   * * caso seja um saleItem já salvo, será o unitValue de quando o item foi salvo pela última vez
   */
  unitValue: 0,
  /** valor mostrado ao usuário na tela de acordo com sua regra de preço (escalonado, promoção, tabela de preço, etc).
   * * já tem o desconto aplicado nele
   */
  price: 0,
  /**
   * valor unitário na config do produto
   */
  originalUnitValue: 0,
  contractualFine: 0,

  grossValue: 0,
  netValue: 0,

  palmBeerCardNumber: null,
  choppGiftCardNumber: null,

  returnQuantityDamaged: 0,
  returnUser: '',
  returnDate: null, // data que comodato/aluguel retornou na loja
  returnDateLimit: null, // data limite do retorno de comodato ou aluguel
  /** Valor total dos itens devolvidos que estão dentro do kit  */
  localReturnedKitNetValue: 0,

  deliveredDate: null,

  createdDate: null,
  createdBy: '',

  lendingProducts: [],
  kitItems: [],

  observation: '',
  inputMethod: null,
  classification: null,
  tieredPrices: [],

  /** itens que está sendo devolvidos no momento */
  willItemBeReturned: false,
  /** item que já houve devolução registrado no pedido */
  hasOrderItemReturn: false,

  isDeleted: false,
  paymentStarted: false,
  priceRules: [],
  priceRuleSelected: {},
})

// #region product to skuProduct

const buildProductToSkuProduct = product => ({
  ...product,
  productId: product.id,
  label: product.name,
})

// #endregion

// #region saleItem to product

const buildSaleItemToProduct = saleItem => {
  const skuData = saleItem.skuEnriched || saleItem.sku

  return {
    ...skuData,
    classification: saleItem.classification,

    skuId: saleItem.sku.id,
    productId: saleItem.sku.product?.id || saleItem.sku.productId,
    productKitId: saleItem.productKitId,

    skuAssociatedId: saleItem.skuAssociatedId,

    priceTableId: saleItem.priceTableId,
    priceTable: saleItem.priceTable || { id: saleItem.priceTableId },

    skuTieredPriceId: saleItem.skuTieredPriceId,
    tieredPrices: saleItem.tieredPrices,

    promotionId: saleItem.promotionId,
    promotionQuantityUnlimited:
      skuData?.promotionQuantityUnlimited || saleItem.promotionQuantityUnlimited,
    promotionQuantityAvailable:
      skuData?.promotionQuantityAvailable || saleItem.promotionQuantityAvailable,
    promotionalPrice: skuData?.promotionalPrice || saleItem.promotionalPrice,
    priceFrom: skuData?.priceFrom || saleItem?.priceFrom,

    quantity: saleItem.quantity,

    unitValue: saleItem.unitValue,
    originalUnitValue: saleItem.originalUnitValue,
    price: saleItem.unitValue,
    originalPrice: saleItem.originalUnitValue,
    contractualFine: saleItem.contractualFine,

    discountType: saleItem.discountType,
    unitDiscount: saleItem.unitDiscount,
    discountPriceTable: saleItem.discountPriceTable,

    label: saleItem.sku.name,
    hasColdOption: saleItem.hasColdOption || saleItem.skuEnriched?.hasColdOption,
  }
}

const buildSaleItemKitToProduct = (saleItemKit, kitItems) => {
  const kitItemProducts = kitItems.map(ki => ki.skuProduct)

  return {
    ...saleItemKit.productKit,
    label: saleItemKit.productKit.name,

    productKitId: saleItemKit.productKit?.id,
    kitItems: kitItemProducts,
    kit: true,

    quantity: saleItemKit.quantity,

    unitValue: saleItemKit.unitValue,
    originalUnitValue: saleItemKit.unitValue,
    price: saleItemKit.unitValue,
    originalPrice: saleItemKit.unitValue,
  }
}

// #endregion

const saleItemUtils = {
  // #region // REMOVE quando todas as migrations do indexedDb forem feitas nos caixas

  oldSaleItemToSaleItem(
    oldSaleItem,
    palmBeerCardNumber,
    newSaleItemReturned,
    { inputMethodEnum, choppGiftCardId } = {}
  ) {
    const initialItem = getInitialSaleItem()
    const isKit = oldSaleItem?.product?.kitItems?.length > 0
    const isExistingItem = !!oldSaleItem.id

    const { product } = oldSaleItem
    const skuProduct = {
      ...product,

      productId: !isKit ? product.id : undefined,
      productKitId: oldSaleItem.productKitId || (isKit ? product.id : undefined),

      skuAssociatedId: oldSaleItem.skuAssociatedId,

      priceTableId: product.priceTableId || 1,
      priceTable: product.priceTable || { id: product.priceTableId || 1 },

      tieredPrices: [],

      promotionalPrice: product.price,

      unitValue: product.price,
      originalUnitValue: product.originalPrice,

      label: product.name,
    }

    const priceTable = oldSaleItem.priceTable || { id: oldSaleItem.priceTableId || 1 }

    let kitItems = []
    let lendingItems = []

    if (product.kitItems?.length > 0) {
      kitItems = product.kitItems.map(oldKitItem =>
        this.oldSaleItemToSaleItem(
          oldKitItem,
          oldSaleItem.palmBeerCardNumber,
          newSaleItemReturned,
          {
            inputMethodEnum: oldSaleItem.inputMethodEnum,
            choppGiftCardId: oldSaleItem.choppGiftCardId,
          }
        )
      )
    }

    if (product.lendingItemAssociated?.length > 0) {
      lendingItems = product.lendingItemAssociated.map(oldLendingItem =>
        this.oldSaleItemToSaleItem(
          {
            ...oldLendingItem,
            productKitId: oldSaleItem.productKitId || skuProduct.productKitId,
          },
          undefined,
          newSaleItemReturned
        )
      )
    }

    const saleItem = {
      ...initialItem,
      ...oldSaleItem,

      skuAssociatedId: oldSaleItem.skuAssociatedId,
      skuTieredPriceId: oldSaleItem.skuTieredPriceId,
      promotionId: oldSaleItem.promotionId,
      productKitId: oldSaleItem.productKitId || skuProduct.productKitId,
      priceTableId: oldSaleItem.priceTableId || 1,
      priceTable,

      palmBeerCardNumber: product.classification === 'Credit' ? palmBeerCardNumber : undefined,
      choppGiftCardId: choppGiftCardId || oldSaleItem.choppGiftCardId,

      skuProduct,
      lendingProducts: lendingItems,
      kitItems,

      quantity: oldSaleItem.amount,
      orderQuantity: oldSaleItem.orderAmount,
      maxQuantity: oldSaleItem.maxAmount,

      inputMethod: inputMethodEnum || oldSaleItem.inputMethodEnum,

      originalUnitValue: product.originalPrice || product.price,
      price: oldSaleItem.unitValue || product.price,
      unitValue: isExistingItem ? oldSaleItem.unitValue : product.price,
      contractualFine: isExistingItem ? oldSaleItem.contractualFine : product.contractualFine,
      discountType: isExistingItem ? oldSaleItem.discountType : product.discountType,
      unitDiscount: isExistingItem ? oldSaleItem.discount : product.unitDiscount,

      hasOrderItemReturn: (newSaleItemReturned || []).some(
        itemReturned => itemReturned.saleItemId === oldSaleItem.id
      ),
    }

    delete saleItem.product

    const hasItemReturnedInKit = (saleItem.kitItems || []).some(
      kitItem => kitItem.hasOrderItemReturn
    )
    if (hasItemReturnedInKit) {
      const kitActualValue = saleItem.kitItems.reduce(
        (total, item) => total.add(item.priceRuleSelected?.priceInfo?.localNetValue || 0),
        new Decimal(0)
      )

      saleItem.localReturnedKitNetValue = new Decimal(saleItem.unitValue || 0)
        .mul(saleItem.quantity)
        .minus(kitActualValue)
        .toNumber()
    }

    const { priceRules } = payBoxUtils.buildPriceRulesFromSaleItem(saleItem)
    const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
      discountType: saleItem.discountType,
      unitDiscount: saleItem.unitDiscount,
      quantity: saleItem.quantity,
      promotionId: saleItem.promotionId,
      priceTableId: saleItem.priceTable?.id,
      productKitId: saleItem.productKitId,
      priceRules,
    })

    return { ...saleItem, priceRuleSelected, priceRules }
  },

  // #endregion

  // #region SaleItemApi To SaleItem
  buildSaleItemFromApi(saleItemFromApi, saleItemsReturnedFromApi) {
    const initialItem = getInitialSaleItem()
    const skuProduct = buildSaleItemToProduct(saleItemFromApi)

    const priceTable = saleItemFromApi.priceTable || { id: saleItemFromApi.priceTableId }

    const saleItem = {
      ...initialItem,
      ...saleItemFromApi,
      classification: saleItemFromApi.classification,

      id: saleItemFromApi.id,
      saleItemAssociatedId: saleItemFromApi.saleItemAssociatedId,
      skuAssociatedId: saleItemFromApi.skuAssociatedId,
      skuTieredPriceId: saleItemFromApi.skuTieredPriceId,
      promotionId: saleItemFromApi.promotionId,
      productKitId: saleItemFromApi.productKitId,
      priceTable,

      palmBeerCardNumber: saleItemFromApi.palmBeerCardNumber || null,
      choppGiftCardNumber: saleItemFromApi.choppGiftCardNumber || null,

      skuProduct,

      quantity: saleItemFromApi.quantity,
      orderQuantity: saleItemFromApi.quantity,
      maxQuantity: saleItemFromApi.promotionId ? saleItemFromApi.quantity : initialItem.maxQuantity,

      discountType: saleItemFromApi.discountType,
      unitDiscount: saleItemFromApi.unitDiscount,
      discountPriceTable: saleItemFromApi.discountPriceTable,

      hasOrderItemReturn: (saleItemsReturnedFromApi || []).some(
        itemReturned => itemReturned.saleItemId === saleItemFromApi.id
      ),

      // dados de retorno de aluguel/comodato
      returnUser: saleItemFromApi.returnUser,
      returnDateLimit: saleItemFromApi.returnDateLimit,
      returnDate: saleItemFromApi.returnDate,
      returnQuantityDamaged: saleItemFromApi.returnQuantityDamaged,

      deliveredDate: saleItemFromApi.deliveredDate,

      price: saleItemFromApi.unitValue,
    }

    const { priceRules } = payBoxUtils.buildPriceRulesFromSaleItem(saleItem)
    const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
      discountType: saleItem.discountType,
      unitDiscount: saleItem.unitDiscount,
      quantity: saleItem.quantity,
      promotionId: saleItem.promotionId,
      priceTableId: saleItem.priceTable?.id,
      productKitId: saleItem.productKitId,
      priceRules,
    })

    return { ...saleItem, priceRuleSelected, priceRules }
  },

  buildSaleItemKitFromApi(saleItemKitFromApi, saleKitItems) {
    const initialItem = getInitialSaleItem()

    const saleItemKit = {
      ...initialItem,
      ...saleItemKitFromApi,
      unitValue: saleItemKitFromApi.unitValue,
      isKit: true,
      skuProduct: buildSaleItemKitToProduct(saleItemKitFromApi, saleKitItems),
      quantity: saleItemKitFromApi.quantity,
      orderQuantity: saleItemKitFromApi.quantity,
      productKitId: saleItemKitFromApi.productKit.id,
      kitItems: saleKitItems,

      hasOrderItemReturn: (saleKitItems || []).some(kitItem => kitItem.hasOrderItemReturn),
      deliveredDate: (saleKitItems || []).find(kitItem => kitItem.deliveredDate)?.deliveredDate,
    }

    if (saleItemKit.hasOrderItemReturn) {
      const kitActualValue = saleKitItems.reduce(
        (total, item) => total.add(item.priceRuleSelected?.priceInfo?.localNetValue || 0),
        new Decimal(0)
      )
      saleItemKit.localReturnedKitNetValue = new Decimal(saleItemKit.unitValue || 0)
        .mul(saleItemKit.quantity)
        .minus(kitActualValue)
        .toNumber()
    }

    const { priceRules } = payBoxUtils.buildPriceRulesFromSaleItem(saleItemKit)
    const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
      quantity: saleItemKit.quantity,
      productKitId: saleItemKit.productKitId,
      priceRules,
    })

    return { ...saleItemKit, priceRuleSelected, priceRules }
  },

  /**
   * Formatar os itens que vem da API com propriedade skuEnriched para agrupar comodatos associados a itens e itens dentro de kit
   * @param {Array} apiItems items da venda
   * @param {Array} apiItemKits kits da venda
   * @returns
   */
  buildSaleItemsAndKitsFromApi(apiSaleItems, apiSaleItemKits, apiSaleItemsReturned) {
    const itemsMapped = _.sortBy(apiSaleItems, ['id']).map(saleItemApi =>
      this.buildSaleItemFromApi(saleItemApi, apiSaleItemsReturned)
    )
    const lendingSaleItems = itemsMapped.filter(saleItem => saleItem.saleItemAssociatedId)

    const items = itemsMapped
      .filter(item => !item.productKitId && !item.saleItemAssociatedId)
      .map(item => {
        const lendingSaleItem = lendingSaleItems.find(lp => lp.saleItemAssociatedId === item.id)
        let lendingList = []
        if (lendingSaleItem) {
          lendingList = [lendingSaleItem]
        }

        return {
          ...item,
          lendingProducts: lendingList,
        }
      })

    const itemKits = apiSaleItemKits.map(kit => {
      const kitItemsResult = itemsMapped.filter(i => i.productKitId === kit.productKit.id)

      const kitItemsAssociated = kitItemsResult.filter(kia => kia.saleItemAssociatedId != null)

      const itemsInKit = kitItemsResult
        .filter(ki => ki.saleItemAssociatedId == null)
        .map(saleKitItem => {
          const itemConfigQuantity = saleKitItem.quantity / (kit.quantity || 1)

          // cenário: itens do kit possuem comodato associado a outro dentro do kit
          const associatedOfThisKitItem = kitItemsAssociated.find(
            kia => kia.saleItemAssociatedId === saleKitItem.id
          )

          return {
            ...saleKitItem,
            skuProduct: { ...saleKitItem.skuProduct, quantity: itemConfigQuantity },
            lendingProducts: associatedOfThisKitItem ? [associatedOfThisKitItem] : [],
          }
        })

      return this.buildSaleItemKitFromApi(kit, itemsInKit)
    })

    return [...items, ...itemKits]
  },
  // #endregion

  // #region Product To saleItem
  buildProductToSaleItem(
    product,
    {
      quantity,
      unitDiscount,
      discountType,
      inputMethod,
      palmBeerCardNumber,
      choppGiftCardId,
      choppGiftCardNumber,
    }
  ) {
    const kitItems = this.buildKitItemsFromProduct(product, {
      quantity,
      inputMethod,
      choppGiftCardId,
      palmBeerCardNumber,
    })
    const lendingItems = this.buildLendingItemsFromProduct(product, { quantity, inputMethod })
    const saleItem = this.buildSaleItemFromProduct(product, {
      quantity,
      unitDiscount,
      discountType,
      inputMethod,
      palmBeerCardNumber,
      choppGiftCardId,
      choppGiftCardNumber,
    })

    saleItem.lendingProducts = lendingItems
    saleItem.kitItems = kitItems ?? []

    return saleItem
  },

  buildKitItemsFromProduct(
    productKit,
    { quantity, inputMethod, choppGiftCardId, palmBeerCardNumber }
  ) {
    if (!productKit?.kitItems || productKit.kitItems.length === 0) {
      return []
    }

    const kitItems = productKit.kitItems?.map(kitItemProduct => {
      const itemQuantity = kitItemProduct.quantity * quantity
      const { priceRules } = payBoxUtils.buildPriceRulesFromProduct(kitItemProduct)
      const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
        price: kitItemProduct.price,
        discountType: kitItemProduct.discountType,
        quantity: itemQuantity,
        unitDiscount: kitItemProduct.unitDiscount,
        tieredPrices: kitItemProduct.tieredPrices,
        priceRules,
        productKitId: kitItemProduct.productKitId,
      })

      const kitLendingProduct = this.buildLendingItemsFromProduct(kitItemProduct, {
        quantity: itemQuantity,
      })

      return {
        ...getInitialSaleItem(),
        productKitId: kitItemProduct.productKitId,
        skuProduct: buildProductToSkuProduct(kitItemProduct),
        lendingProducts: [...kitLendingProduct],
        priceTable: kitItemProduct.priceTable,

        quantity: itemQuantity,
        discountType: kitItemProduct.discountType,
        unitDiscount: kitItemProduct.unitDiscount,

        price: priceRuleSelected.priceInfo.price,
        unitValue: priceRuleSelected.priceInfo.unitValue,
        originalUnitValue: priceRuleSelected.priceInfo.originalPrice,
        contractualFine: priceRuleSelected.priceInfo.contractualFine,

        choppGiftCardId,
        palmBeerCardNumber:
          kitItemProduct.classification === 'Credit' ? palmBeerCardNumber : undefined,

        inputMethod,
        hasColdOption: kitItemProduct?.hasColdOption,

        priceRuleSelected,
        priceRules,
      }
    })

    return localListEnrichLocalId(kitItems)
  },

  buildLendingItemsFromProduct(product, { quantity, inputMethod }) {
    const lendingProduct = product?.lendingProductAssociated

    if (!lendingProduct) return []

    const lendingQuantity = lendingProduct.quantity * quantity
    const { priceRules } = payBoxUtils.buildPriceRulesFromProduct({
      ...lendingProduct,
      priceTable: product.priceTable,
      productKitId: product.productKitId,
    })

    const {
      // priceInfo: lendingPriceInfo,
      // netValue: lendingNetValue,
      priceRuleSelected,
    } = payBoxUtils.calculateItemPrice({
      price: lendingProduct.price,
      discountType: lendingProduct.discountType,
      unitDiscount: lendingProduct.unitDiscount,
      quantity: lendingQuantity,
      tieredPrices: lendingProduct.tieredPrices,
      priceTableId: product.priceTable?.id,
      productKitId: product.productKitId,
      priceRules,
    })

    const { priceInfo: lendingPriceInfo } = priceRuleSelected

    const lendingItemList = [
      {
        ...getInitialSaleItem(),
        productKitId: product.productKitId,
        skuAssociatedId: product.skuId,

        priceTable: lendingProduct.priceTable,
        skuProduct: buildProductToSkuProduct(lendingProduct),
        hasColdOption: product?.hasColdOption,

        discountType: lendingProduct.discountType,
        unitDiscount: lendingProduct.unitDiscount,
        quantity: lendingQuantity,

        price: lendingPriceInfo.price,
        unitValue: lendingPriceInfo.unitValue,
        originalUnitValue: lendingPriceInfo.originalPrice,
        contractualFine: lendingPriceInfo.contractualFine,

        inputMethod,

        priceRules,
        priceRuleSelected,
      },
    ]

    return localListEnrichLocalId(lendingItemList)
  },

  buildSaleItemFromProduct(
    product,
    {
      quantity,
      unitDiscount,
      discountType,
      inputMethod,
      palmBeerCardNumber,
      choppGiftCardId,
      choppGiftCardNumber,
    }
  ) {
    const { priceRules } = payBoxUtils.buildPriceRulesFromProduct(product)

    const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
      price: product?.price || 0,
      originalPrice: product?.originalPrice,
      promotionId: product?.promotionId,
      tieredPrices: product?.tieredPrices,
      priceTableId: product.priceTable?.id,
      productKitId: product.productKitId,

      discountType,
      unitDiscount,
      quantity,
      priceRules,
    })

    const applyChoppCardNumber =
      palmBeerCardNumber &&
      product.kit &&
      product.kitItems.some(kitItem => kitItem.classification === 'Credit')

    const saleItem = {
      ...getInitialSaleItem(),
      promotionId: product.promotionId,
      productKitId: product.productKitId,
      priceTable: product.priceTable,
      skuProduct: buildProductToSkuProduct(product),
      hasColdOption: product?.hasColdOption,

      quantity,
      discountType,
      unitDiscount,
      promotionPrice: product.promotionalPrice,
      discountPriceTable: product.priceTable?.discount || 0,

      price: priceRuleSelected.priceInfo.price,
      unitValue: priceRuleSelected.priceInfo.unitValue,
      originalUnitValue: priceRuleSelected.priceInfo.originalPrice,
      contractualFine: priceRuleSelected.priceInfo.contractualFine,

      palmBeerCardNumber: applyChoppCardNumber ? palmBeerCardNumber : null,
      choppGiftCardId,
      choppGiftCardNumber,

      inputMethod,

      priceRules,
      priceRuleSelected,
    }

    return saleItem
  },

  // #endregion

  // #region refresh saleItem using product
  saleItemForceRefreshUsingProduct(saleItem, product) {
    const getLendingItemRefreshed = (_saleItem, lendingItemsFromProduct) =>
      _saleItem?.lendingProducts?.map(saleLendingItem => {
        const lendingItemFromProductFound = lendingItemsFromProduct?.find(
          productLendingItem =>
            productLendingItem.skuProduct.skuId === saleLendingItem.skuProduct.skuId
        )

        if (!lendingItemFromProductFound) {
          return saleLendingItem
        }

        return {
          ...saleLendingItem,
          skuProduct: lendingItemFromProductFound?.skuProduct,
          priceRuleSelected: lendingItemFromProductFound?.priceRuleSelected,
          priceRules: lendingItemFromProductFound?.priceRules,
        }
      })

    const getKitItemsRefreshed = (_saleItem, kitItemsFromProduct) =>
      _saleItem.kitItems?.map(saleKitItem => {
        const kitItemFromProduct = kitItemsFromProduct?.find(
          productKitItem =>
            productKitItem.skuProduct.productKitId === saleKitItem.skuProduct.productKitId &&
            productKitItem.skuProduct.skuId === saleKitItem.skuProduct.skuId
        )

        if (!kitItemFromProduct) {
          return saleKitItem
        }

        const lendingItemsRefreshedFromKitItem = getLendingItemRefreshed(
          saleKitItem,
          saleKitItem?.lendingProducts
        )

        return {
          ...saleKitItem,
          lendingProducts: lendingItemsRefreshedFromKitItem,
          skuProduct: kitItemFromProduct.skuProduct,
          priceRuleSelected: kitItemFromProduct?.priceRuleSelected,
          priceRules: kitItemFromProduct?.priceRules,
        }
      })

    const { priceInfo } = saleItem.priceRuleSelected

    const { priceRuleSelected, priceRules, skuProduct, kitItems, lendingProducts } =
      this.buildProductToSaleItem(product, {
        discountType: priceInfo.discountType,
        quantity: saleItem.quantity,
        unitDiscount: priceInfo.unitDiscount,
      })

    // seta forçadamente dados que viriam do SaleItem do banco
    const saleItemRefreshed = {
      ...saleItem,
      promotionId: product.promotionId,
      productKitId: product.productKitId,
      hasColdOption: product?.hasColdOption,
      promotionPrice: product.promotionalPrice,

      priceTable: product.priceTable,
      discountPriceTable: product.priceTable?.discount || 0,

      skuProduct,
      kitItems: getKitItemsRefreshed(saleItem, kitItems),
      lendingProducts: getLendingItemRefreshed(saleItem, lendingProducts),
      priceRuleSelected,
      priceRules,

      originalUnitValue: priceRuleSelected.priceInfo.originalPrice,
      unitValue: priceRuleSelected.priceInfo.price,
    }

    return saleItemRefreshed
  },

  // #endregion

  // #region update saleItem
  updateSaleItemOrSaleKit(
    saleItemOrKit,
    { quantity, unitDiscount, discountType, isFromReturnedItem = false }
  ) {
    if (saleItemOrKit.kitItems?.length > 0) {
      const saleKit = this.updateSaleItemKit(saleItemOrKit, { quantity, isFromReturnedItem })
      return saleKit
    }

    const saleItemResult = this.updateSaleItem(saleItemOrKit, {
      quantity,
      unitDiscount,
      discountType,
      isSamePriceRule: isFromReturnedItem,
    })
    return saleItemResult
  },

  updateSaleItem(
    saleItem,
    { quantity, unitDiscount, discountType, refreshPriceRules = false, isSamePriceRule = false }
  ) {
    let localPriceRules = saleItem.priceRules

    if (refreshPriceRules) {
      const { priceRules } = payBoxUtils.buildPriceRulesFromSaleItem(saleItem)
      localPriceRules = priceRules
    }

    const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
      discountType: discountType ?? saleItem.discountType,
      unitDiscount: unitDiscount ?? saleItem.unitDiscount,
      quantity,
      promotionId: saleItem.skuProduct?.promotionId,
      priceTableId: saleItem.skuProduct?.priceTable?.id,
      productKitId: saleItem.skuProduct?.productKitId,
      priceRules: localPriceRules,
      forcedPriceRuleType: isSamePriceRule ? saleItem.priceRuleSelected : null,
    })

    const saleItemResult = {
      ...saleItem,
      priceRules: localPriceRules,
      priceRuleSelected,
      quantity,
    }

    const lendingItems = this.updateSaleLendingItems(saleItemResult, {
      quantity,
    })

    saleItemResult.lendingProducts = lendingItems

    return saleItemResult
  },

  /** update do item de kit (dentro dele há kitItems) */
  updateSaleItemKit(saleItemKit, { quantity, isFromReturnedItem }) {
    if (!saleItemKit.kitItems) return saleItemKit

    const kitItems = saleItemKit.kitItems?.map(kitItem => {
      const isToUseItemQuantity = kitItem.willItemBeReturned || kitItem.hasOrderItemReturn
      const itemQuantity = isToUseItemQuantity
        ? kitItem.quantity
        : kitItem.skuProduct.quantity * quantity

      const { priceRuleSelected } = payBoxUtils.calculateItemPrice({
        discountType: kitItem.discountType,
        unitDiscount: kitItem.unitDiscount,
        quantity: itemQuantity,
        promotionId: kitItem.skuProduct?.promotionId,
        priceTableId: kitItem.skuProduct?.priceTable?.id,
        productKitId: kitItem.skuProduct?.productKitId,
        priceRules: kitItem.priceRules,
      })

      const kitItemWithPrice = {
        ...kitItem,
        quantity: itemQuantity,
        priceRuleSelected,
      }

      const kitLendingItems = this.updateSaleLendingItems(kitItemWithPrice, {
        quantity: itemQuantity,
      })

      return {
        ...kitItemWithPrice,
        lendingProducts: kitLendingItems,
      }
    })

    // cenário: devolução de item dentro do kit
    //    - total a ser mostrado não é "quantity x unitValue" pois soma dos itens que compões o kit não bateria com unitValue do kit
    const localSaleItemKit = { ...saleItemKit, kitItems }
    if (localSaleItemKit.hasOrderItemReturn || localSaleItemKit.willItemBeReturned) {
      const kitActualValue = kitItems.reduce(
        (total, item) => total.add(item.priceRuleSelected?.priceInfo?.localNetValue || 0),
        new Decimal(0)
      )
      localSaleItemKit.localReturnedKitNetValue = new Decimal(
        localSaleItemKit.priceRuleSelected?.priceInfo?.localGrossValue || 0
      )
        .minus(kitActualValue)
        .toNumber()
    } else {
      localSaleItemKit.localReturnedKitNetValue = 0
    }

    const itemKit = this.updateSaleItem(localSaleItemKit, {
      quantity,
      discountType: saleItemKit.discountType,
      unitDiscount: saleItemKit.unitDiscount,
      refreshPriceRules: isFromReturnedItem,
    })

    return itemKit
  },

  updateSaleLendingItems(saleItem, { quantity }) {
    if (!saleItem.lendingProducts?.length) {
      return saleItem.lendingProducts
    }

    // const lendingQuantity = lendingItem.skuProduct.quantity * quantity
    return saleItem.lendingProducts.map(lendingItem => ({
      ...lendingItem,
      quantity,
    }))
  },
  // #endregion

  // #region update saleItem with returned
  updateSaleItemWithItemReturned(saleItems, returnedItem) {
    if (returnedItem.itemReturnedId) return null

    const newItemQuantity = returnedItem.maxQuantity - (returnedItem.quantity || 0)
    const willItemBeReturned = newItemQuantity !== returnedItem.maxQuantity

    const kitIndex = saleItems.findIndex(i =>
      i.kitItems?.some(ki => ki.id === returnedItem.saleItemId)
    )
    if (kitIndex > -1) {
      const kit = saleItems[kitIndex]
      const kitItemIndex = kit.kitItems.findIndex(ki => ki.id === returnedItem.saleItemId)

      if (kitItemIndex > -1) {
        kit.kitItems.splice(kitItemIndex, 1, {
          ...kit.kitItems[kitItemIndex],
          quantity: newItemQuantity,
          willItemBeReturned,
        })

        const kitResult = {
          ...kit,
          willItemBeReturned: kit.kitItems.some(ki => ki.willItemBeReturned),
        }
        return this.updateSaleItemOrSaleKit(kitResult, {
          quantity: kitResult.quantity,
          isFromReturnedItem: true,
        })
      }

      return null
    }

    const itemIndex = saleItems.findIndex(i => i.id === returnedItem.saleItemId)
    if (itemIndex > -1) {
      const saleItemResult = {
        ...saleItems[itemIndex],
        quantity: newItemQuantity,
        willItemBeReturned,
      }
      return this.updateSaleItemOrSaleKit(saleItemResult, {
        quantity: saleItemResult.quantity,
        isFromReturnedItem: true,
      })
    }

    return null
  },
  // #endregion

  // #region getters
  findLocalItemWithoutPromotion(saleItemList, saleItem) {
    const localItemWithoutPromotion = (saleItemList || []).find(
      itemAdded =>
        itemAdded.localId &&
        itemAdded.skuProduct.skuId === saleItem.skuProduct.skuId &&
        itemAdded.skuProduct.kit === saleItem.skuProduct.kit &&
        _.isNil(itemAdded.skuProduct.promotionId)
    )

    return localItemWithoutPromotion
  },
  // #endregion
}

export default saleItemUtils
