var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateInput",
    _vm._b(
      {
        class: _vm.showLabel && _vm.required ? "required" : "",
        attrs: {
          type: _vm.isReadOnly ? "label" : "vue-select",
          multiple: _vm.multiple,
          label: _vm.showLabel ? _vm.label : "",
          placeholder: _vm.placeholder,
          options: _vm.localCompanyOptions,
          validation: _vm.required ? "required" : "",
        },
        on: { input: _vm.handleInput },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getEmptyTableMessage(
                        _vm.$tc("COMPANY.NAME"),
                        "female"
                      )
                    ) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      },
      "FormulateInput",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }