<template>
  <div>
    <!-- <FormulateInput
      v-if="isReadOnly"
      v-model="content"
      v-bind="$attrs"
      type="label"
      :label="label"
      :class="required ? 'required' : ''"
      :validation="required ? 'required' : ''"
      :options="options"
    /> -->
    <div>
      <FormulateInput
        ref="managementAccountLabel"
        v-model="contentLabel"
        v-bind="$attrs"
        type="label"
        :label="showLabel ? label : ''"
        :class="showLabel && required ? 'required' : ''"
        :validation="required ? 'required' : ''"
        :options="options"
        :icons="icons"
        :icon="!isReadOnly ? 'pencil' : null"
        :icon-title="$t('Selecionar Conta Gerencial')"
        @edit="showModal = true"
        @clean="cleanSelection"
      />
      <!-- <FormulateInput
        v-model="content"
        v-bind="$attrs"
        type="vue-select"
        :multiple="multiple"
        :label="label"
        :placeholder="$t('Selecione')"
        :options="options"
        :class="required ? 'required' : ''"
        :validation="required ? 'required' : ''"
        @input="handleInput"
      /> -->
      <!-- <button @click="showModal = true">
        Editar
      </button> -->
    </div>
    <b-modal
      id="modal-management-account"
      ref="modal"
      v-model="showModal"
      no-close-on-backdrop
      size="md"
      centered
      scrollable
      style="height: 100%"
      :ok-title="$t('Confirmar')"
      :cancel-title="$t('Cancelar')"
      @ok="confirm"
    >
      <template #modal-title>
        <p class="h4">
          {{ $t('Contas Gerenciais') }}
        </p>
      </template>

      <b-row>
        <b-col md="12">
          <FormulateInput
            v-model="filter"
            name="filter"
            type="text"
            :placeholder="$t('Filtrar')"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <tree
            ref="treeView"
            :data="treeData"
            :options="treeOptions"
            :filter="filter"
          >
            <span
              slot-scope="{ node }"
              class="tree-text"
            >
              <template v-if="!node.hasChildren()">
                <i class="ion-android-star" />
                <span>{{ node.text }}</span>
                <e-instructions
                  v-if="node.data.description"
                  :id="node.data.id"
                  class="ml-1"
                  :instruction="node.data.description"
                  :title="$t('Descrição')"
                />
              </template>

              <template v-else>
                <i :class="[node.expanded() ? 'ion-android-folder-open' : 'ion-android-folder']" />
                {{ node.text }}
              </template>
            </span>
          </tree>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BRow, BCol, BModal } from 'bootstrap-vue'
import _ from 'lodash'
import EInstructions from '../EInstructions.vue'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    EInstructions,
  },

  mixins: [],

  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default() {
        return this.$t('Conta Gerencial')
      },
    },
    placeholder: {
      type: String,
      default() {
        return null // this.$t('Selecione')
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    /// All | Input | Output
    type: {
      type: String,
      default: 'All',
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    onlyActive: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      contentLabel: '',
      content: null,
      selectedManagementAccount: null,
      showModal: false,
      filter: null,
      treeOptions: {
        checkbox: false,
        filter: {
          emptyText: 'Nenhuma opção encontrada',
          matcher(query, node) {
            return new RegExp(query, 'i').test(node.text)
          },
          plainList: false,
          showChildren: true,
        },
      },
    }
  },

  computed: {
    ...mapGetters('common/managementAccounts', [
      'getComboManagementAccounts',
      'getComboInputManagementAccounts',
      'getComboOutputManagementAccounts',
    ]),
    icons() {
      return [
        {
          eventName: 'edit',
          icon: 'pencil',
          title: this.$t('Selecionar Conta Gerencial'),
          hide: this.isReadOnly,
        },
        {
          eventName: 'clean',
          icon: 'eraser',
          variant: 'danger',
          title: this.$t('Limpar seleção'),
          hide: this.isReadOnly || !this.selectedManagementAccount,
        },
      ].filter(icon => !icon.hide)
    },
    treeData() {
      if (!this.showModal) {
        return []
      }
      let treeData = []

      const options = !this.onlyActive
        ? () => this.options
        : () => this.options.filter(option => option.active === this.onlyActive)

      const accountsGroupedByDre = _.groupBy(options(), ma => ma.dreClassification.name)

      Object.keys(accountsGroupedByDre).forEach(dre => {
        const dreNode = {
          text: dre,
          data: dre,
          children: [],
        }
        accountsGroupedByDre[dre].forEach(managementAccount => {
          const managementAccountNode = {
            text: managementAccount.name,
            data: managementAccount,
            children: [],
          }
          dreNode.children.push(managementAccountNode)
        })
        dreNode.children = _.sortBy(dreNode.children, node => node.text)
        treeData.push(dreNode)
      })

      treeData = _.sortBy(treeData, node => node.text)
      // this.$refs.modal.$refs.treeView.setModel(treeData)
      return treeData
    },
    options() {
      switch (this.type) {
        case 'Output':
          return this.getComboOutputManagementAccounts
        case 'Input':
          return this.getComboInputManagementAccounts
        default:
          return this.getComboManagementAccounts
      }
    },
  },

  watch: {
    value(val) {
      this.content = val
      this.updateSelectedManagementAccount(val)
    },

    selectedManagementAccount(val) {
      this.contentLabel = val?.name || this.placeholder
    },
  },

  created() {
    if (this.value) this.content = String(this.value)
  },

  async mounted() {
    if (!this.getComboManagementAccounts?.length) {
      await this.fetchManagementAccounts()
    }
    this.updateSelectedManagementAccount(this.content)
  },

  methods: {
    ...mapActions('common/managementAccounts', ['fetchManagementAccounts']),
    handleInput() {
      this.$emit('input', this.content)
    },
    cleanSelection() {
      this.content = null
      this.selectedManagementAccount = null
      this.$emit('input', this.content)
    },
    confirm(bvModalEvent) {
      bvModalEvent.preventDefault()
      const [selectedNode] = this.$refs.treeView.tree.selectedNodes
      if (!selectedNode?.parent) {
        this.showInvalidDataMessage({ message: this.$t('Selecione uma Conta Gerencial') })
      } else {
        this.$refs.managementAccountLabel.performValidation()
        this.content = selectedNode.data.id
        this.selectedManagementAccount = selectedNode.data
        this.$emit('input', this.content)
        this.$nextTick(() => {
          this.$bvModal.hide('modal-management-account')
          this.filter = null
        })
      }
    },

    updateSelectedManagementAccount(val) {
      if (val) {
        this.selectedManagementAccount = this.options.find(item => item.id === parseInt(val, 10))
      } else {
        this.selectedManagementAccount = null
        this.contentLabel = this.placeholder
      }
    },
  },
}
</script>
