/* eslint-disable import/prefer-default-export */
import isElectron from 'is-electron'
import axios from '@axios'
import store from '@/store'
import { isUserLoggedIn } from '@/auth/utils'
import { toMiliseconds } from '@/utils/promise-util'

const environment = window.env || 'local'
const isLocal = environment === 'local'

let initialized = false
let timeout = null

// force stop it when window is unloaded
window.addEventListener('beforeunload', () => {
  console.debug('beforeunload triggered on frontend-send-to-s3')
  initialized = false
  clearTimeout(timeout)
})

const sendToS3BackupsAndLogs = async () => {
  if (!initialized) return

  try {
    await new Promise(resolve => {
      timeout = setTimeout(resolve, toMiliseconds({ min: 10 }))
    })

    let payboxId = store.state.pages?.pdv?.payBoxConfiguration?.payBox?.id

    if (!payboxId) {
      const identify = await window.electronAPI.system.identifyPayboxRead()
      if (identify && identify.payboxId) {
        payboxId = identify.payboxId
      }
    }

    if (payboxId && isUserLoggedIn()) {
      const { data } = await axios.get(`/api/support/workstation/${payboxId}/logs`)
      if (data.currentLogsRequested) {
        await window.electronAPI.system.workstationSendS3Current()
      } else if (data.backupToS3Enabled) {
        await window.electronAPI.system.workstationSendS3Backup()
      }
    }
  } catch (e) {
    // ignored
  }

  await sendToS3BackupsAndLogs()
}

export const initSendToS3BackupsAndLogs = async () => {
  if (initialized) return

  if (!isElectron() || isLocal) {
    console.debug('Skipping backup to S3')
    return
  }

  initialized = true

  await sendToS3BackupsAndLogs()
}
