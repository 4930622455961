import store from '@/store'

function handleCapsLock(e) {
  if (e.getModifierState) {
    const capsLockActive =
      e.keyCode === 20 ? !e.getModifierState('CapsLock') : e.getModifierState('CapsLock')

    store.commit('app/keyboard/SET_CAPS_LOCK_STATE', capsLockActive)
  }
}

export default {
  namespaced: true,

  state: {
    capsLockState: false,
  },

  getters: {},

  mutations: {
    SET_CAPS_LOCK_STATE(state, capsLockState) {
      state.capsLockState = capsLockState
    },
  },

  actions: {
    initCapsLockListener() {
      document.addEventListener('keydown', handleCapsLock)
    },
    destroyCapsLockListener() {
      document.removeEventListener('keydown', handleCapsLock)
    },
  },
}
