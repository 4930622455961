/* eslint-disable no-param-reassign */
import LogRocket from 'logrocket'
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'
import createPlugin from 'logrocket-vuex'
import isElectron from 'is-electron'
import { getUserData } from '@/auth/utils'
import router from '@/router'
import store from '@/store'
import axios from '@axios'
import { getAppVersions } from './utils/app-utils'

const environment = window.env || 'local'
const isLocal = environment === 'local'

let initialized = false

const sanitizeFields = [
  'Authorization',
  'authorization',
  'access_token',
  'refresh_token',
  'cpf',
  'cnpj',
  'document',
]
const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(sanitizeFields)

const mutationSanitizer = mutation => {
  if (!initialized) return mutation

  const fakeRequest = {
    body: JSON.stringify(mutation),
    headers: {},
    method: 'POST',
  }
  const { body: sanitized } = responseSanitizer(fakeRequest)
  return sanitized
}

const init = token => {
  if (initialized || !token) return

  const appVersion = JSON.stringify(getAppVersions())

  LogRocket.init(token, {
    release: appVersion,
    network: {
      requestSanitizer: request => {
        const { body: sanitized } = requestSanitizer(request)
        request.body = sanitized

        Object.keys(request.headers)
          .filter(h => h && sanitizeFields.includes(h.toLowerCase()))
          .forEach(h => {
            request.headers[h] = null
          })
        return request
      },
      responseSanitizer,
    },
    dom: {
      // TODO: deixando desabilitado por enqt, até estar melhor validado em produção
      // inputSanitizer: isProd,
    },
  })

  initialized = true
}

async function checkEnabled(config, userData, isTefAction) {
  // disabled globally
  const enabled = config?.enabled
  if (!enabled) return enabled

  const users = config?.enabledUserNames || []
  const paths = config?.enabledUrlPaths || []
  const storeIds = config?.enabledStoreIds || []
  const payBoxIds = config?.enabledPayBoxIds || []

  const tefMonitoring = config?.enabledTefMonitoring || false

  const storeOrPayboxEnabled = async () => {
    // all stores/payboxes enabled
    if (!storeIds.length && !payBoxIds.length) return true

    let payboxId = store.state.pages?.pdv?.payBoxConfiguration?.payBox?.id
    let storeId = store.state.pages?.pdv?.payBoxConfiguration?.payBox?.store?.id

    if (!payboxId) {
      const identify = isElectron() ? await window.electronAPI.system.identifyPayboxRead() : null
      if (identify && identify.payboxId) {
        payboxId = identify.payboxId
        storeId = identify?.storeId
      }
    }

    return payBoxIds.includes(payboxId) || storeIds.includes(storeId)
  }

  const userEnabled = () => {
    // all users enabled
    if (!users.length) return true

    return users.map(u => u.toLowerCase()).includes(userData.userName?.toLowerCase())
  }

  const pathEnabled = () => {
    // all paths enabled
    if (!paths.length) return true

    return paths.some(p => router.currentRoute.path.startsWith(p))
  }

  const tefMonitoringEnabled = () => {
    if (!tefMonitoring) return true

    return tefMonitoring === isTefAction
  }

  return (
    (await storeOrPayboxEnabled()) && userEnabled() && tefMonitoringEnabled() && pathEnabled()
  )
}

const vuexPlugin = () => [createPlugin(LogRocket, mutationSanitizer)]

const monitoringLogout = () => {
  if (!initialized) return

  LogRocket.startNewSession()
}

let monitoringLastFetch
const monitoringCacheDuration = isLocal ? 0 : 10 * 60 * 1000 // 10 minutes in milliseconds
let monitoringCacheData = null
const fetchMonitoringConfig = async () => {
  const now = Date.now()

  if (!monitoringLastFetch || now - parseInt(monitoringLastFetch, 10) > monitoringCacheDuration) {
    const { data } = await axios.get('/api/parameter/monitoring.config')
    const config = JSON.parse(data?.value || {})
    monitoringCacheData = config?.front?.logRocket
    monitoringLastFetch = now
  }

  return monitoringCacheData
}

const initMonitoring = async ({ isTefAction }) => {
  try {
    if (isLocal) return

    const userData = getUserData()
    const configData = await fetchMonitoringConfig()
    const enabled = await checkEnabled(configData, userData, isTefAction)
    if (!enabled) return

    init(configData.token)

    const userId = userData.userName
    const userInfos = {
      email: userData.email,
      user: userData.userName,
      environment,
      electron: isElectron().toString(),
    }

    LogRocket.identify(userId, userInfos)
  } catch (err) {
    console.error(err)
  }
}

export { initMonitoring, monitoringLogout, vuexPlugin }
