<template>
  <FormulateInput
    v-model="content"
    v-bind="$attrs"
    type="vue-select"
    :multiple="multiple"
    :label="label"
    :placeholder="$t('Selecione')"
    :options="options"
    :class="required ? 'required' : ''"
    :validation="required ? 'required' : ''"
    @input="handleInput"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {},

  mixins: [],

  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default() {
        return this.$t('Conta Corrente')
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },

    /**
     * Lista apenas registros ativos no combo
     */
    activeOptions: {
      type: Boolean,
      default: false,
    },

    /**
     * Opções válidas: `All` | `Internal` | `External` | `Main`
     */
    type: {
      type: Array,
      default: () => ['All'],
      validator(value) {
        // The value must match one of these strings
        return value.every(v => ['All', 'Internal', 'External', 'Main'].includes(v))
      },
    },

    /**
     * Filtra apenas os checkingAccounts da loja que for passada.
     * Caso não passe, trará de todas as lojas.
     */
    storeId: {
      type: [String, Number],
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      content: null,
      storeIdLocal: null,
    }
  },

  computed: {
    ...mapGetters('app', ['checkingAccountsOptions']),

    options() {
      let optionsList = this.checkingAccountsOptions
      if (this.activeOptions) {
        optionsList = optionsList.filter(checkAcc => checkAcc.active === true)
      }

      let tempStoreId = this.storeId
      if (typeof tempStoreId === 'string') {
        tempStoreId = Number(tempStoreId)
      }
      if (tempStoreId && optionsList) {
        optionsList = optionsList.filter(checkingAcc => checkingAcc.store.id === tempStoreId)
      }

      return optionsList.filter(checkingAcc => {
        if (this.type.includes('All')) return true

        let matches = false
        matches = matches || checkingAcc.accountNumber != null
        if (this.type.includes('Main')) {
          matches = matches || checkingAcc.isMain === true
        }
        if (this.type.includes('Internal')) {
          matches = matches || checkingAcc.isInternal === true
        }
        if (this.type.includes('External')) {
          matches = matches || checkingAcc.isInternal === false
        }
        return matches
      })
    },
  },

  watch: {
    value(val) {
      this.content = val
    },
    // usado local pois o storeId do props não executava na aparição do modal (momento de criação do input)
    storeIdLocal(val) {
      if (val) {
        if (this.options?.length === 1) {
          this.$emit('single-option', this.options[0].id.toString())
        }
      }
    },
  },

  created() {
    if (this.value) this.content = String(this.value)
  },

  async mounted() {
    if (!this.checkingAccountsOptions?.length) {
      await this.fetchCheckingAccounts()
    }

    this.storeIdLocal = this.storeId
  },

  methods: {
    ...mapActions('app', ['fetchCheckingAccounts']),

    handleInput() {
      this.$emit('input', this.content)
    },
  },
}
</script>
